import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type UiSliceType = {
  theme: 'light' | 'dark' | undefined;
  direction: 'rtl' | 'ltr';
  language: string;
  preferences: Object;
  ui_configs: {
    crl: Array<string> | [];
    [key: string]: any;
  };
  showUpgradeModal: boolean;
};

const initialState = {
  theme: 'light',
  direction: 'ltr',
  language: 'en',
  preferences: {},
  ui_configs: {
    crl: [],
  },
  showUpgradeModal: false,
} as UiSliceType;

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleTheme: (state, action) => {
      switch (action.payload) {
        case 'light':
          state.theme = 'light';
          break;
        case 'dark':
          state.theme = 'dark';
          break;
        default:
          state.theme = 'light';
      }
    },
    layoutDirection: (state, action) => {
      if (action.payload === 'fa' || action.payload === 'sa') state.direction = 'rtl';
      else state.direction = 'ltr';
    },
    setAllInOneChosenFeatures: (state, action) => {
      state.ui_configs.crl = action.payload;
    },
    toggleUpgradePlanModal: (state) => {
      state.showUpgradeModal = !state.showUpgradeModal;
    },
    changeLanguage: (state, { payload }) => {
      state.language = payload;
    },
    resetUi: () => initialState,
  },
  extraReducers: {},
});

export const selectCurrentDirection = (state: RootState) => state.ui.direction;
export const selectCurrentThemeColor = (state: RootState) => state.ui.theme;
export const selectAllInOneChosenFeatures = (state: RootState) => state.ui.ui_configs.crl;
export const selectUpgradePlanModal = (state: RootState) => state.ui.showUpgradeModal;

export const {
  toggleTheme,
  layoutDirection,
  setAllInOneChosenFeatures,
  toggleUpgradePlanModal,
  changeLanguage,
  resetUi,
} = uiSlice.actions;

export default uiSlice.reducer;
