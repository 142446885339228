import { message } from 'antd';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import uiService from '../../features/ui/uiService';
import UiError from '../errors/UiError';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  private onClose() {
    //window.location.href = '/dashboard'
  }

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //Call api to pass the error to kafka
    console.error('Uncaught error:', error, errorInfo);

    let customError = {
      info: {
        message: error.message,
        code: 100,
        details: errorInfo,
        stack: error.stack,
      },
    };

    try {
      await uiService.errorReport(customError);
    } catch (error) {
      // Do log
    } finally {
      message.error(
        'Unfortunately something went wrong... we will check the error occurred as soon as possible',
        5,
        this.onClose
      );
    }
  }

  public render() {
    if (this.state.hasError) {
      return <UiError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
