import { apiSlice, getToken, ResponseData } from './../api/apiSlice';

export const extendedBillingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query<any, string>({
      query: (customer_identification) => ({
        url: `${customer_identification}/billing/plan-status`,
        method: 'GET',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      providesTags: ['PlanStatus'],
      transformResponse: (responseData: ResponseData) => responseData.element,
    }),
    getSubscriptionHistory: builder.query<any, { customer_identification: string; plan: string }>({
      query: ({ customer_identification, plan }) => ({
        url: `${customer_identification}/billing/${plan}/subscription-detail`,
        method: 'GET',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'PlanSubscriptionHistory', id: arg.plan }],
      transformResponse: (responseData: ResponseData) => responseData.element,
    }),
    getPlanCards: builder.query<any, { customer_identification: string; plan: string }>({
      query: ({ customer_identification, plan }) => ({
        url: `${customer_identification}/billing/${plan}/subscription-payment-card-detail`,
        method: 'GET',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'PlanCards', id: arg.plan }],
      transformResponse: (responseData: ResponseData) => responseData.element,
    }),
    getPlanDetailedInfo: builder.query<any, { customer_identification: string; plan: string }>({
      query: ({ customer_identification, plan }) => ({
        url: `${customer_identification}/billing/${plan}/plan-detailed-info`,
        method: 'GET',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'PlanDetailedInfo', id: arg.plan }],
      transformResponse: (responseData: ResponseData) => responseData.element,
    }),
    postCancelSubscription: builder.mutation<any, { customer_identification: string; plan: string }>({
      query: ({ customer_identification, plan }) => ({
        url: `${customer_identification}/billing/${plan}/cancel-subscription`,
        method: 'POST',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [{ type: 'PlanCards', id: arg.plan }, { type: 'PlanDetailedInfo', id: arg.plan }, { type: 'PlanStatus' }]
          : [],
    }),
    putMakeCardAsDefault: builder.mutation<
      any,
      { customer_identification: string; plan: string; payment_method_id: string }
    >({
      query: ({ customer_identification, plan, payment_method_id }) => ({
        url: `${customer_identification}/billing/${plan}/${payment_method_id}/update-subscription-card`,
        method: 'PUT',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              { type: 'PlanCards', id: arg.plan },
              { type: 'PlanDetailedInfo', id: arg.plan },
            ]
          : [],
    }),
    removeSubscriptionCard: builder.mutation<
      any,
      { customer_identification: string; plan: string; payment_method_id: string }
    >({
      query: ({ customer_identification, plan, payment_method_id }) => ({
        url: `${customer_identification}/billing/${plan}/${payment_method_id}/remove-payment-method`,
        method: 'DELETE',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              { type: 'PlanCards', id: arg.plan },
              { type: 'PlanDetailedInfo', id: arg.plan },
            ]
          : [],
    }),
    freeSubscription: builder.mutation<any, { customer_identification: string; email: string }>({
      query: ({ customer_identification, email }) => ({
        url: `${customer_identification}/billing/create-free-subscription`,
        method: 'POST',
        headers: {
          'x-access-token': getToken() as string,
        },
        data: {
          email,
        },
      }),
    }),
  }),
});

export const {
  useGetPlansQuery,
  useGetSubscriptionHistoryQuery,
  useGetPlanCardsQuery,
  usePostCancelSubscriptionMutation,
  usePutMakeCardAsDefaultMutation,
  useRemoveSubscriptionCardMutation,
  useFreeSubscriptionMutation,
} = extendedBillingSlice;
