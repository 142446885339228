import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './i18n/locales/en/translation.json';
import fr from './i18n/locales/fr/translation.json';
import cn from './i18n/locales/cn/translation.json';
import es from './i18n/locales/es/translation.json';
import fa from './i18n/locales/fa/translation.json';
import { default as indian } from './i18n/locales/in/translation.json';
import pt from './i18n/locales/pt/translation.json';
import sa from './i18n/locales/sa/translation.json';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  cn: {
    translation: cn,
  },
  es: {
    translation: es,
  },
  fa: {
    translation: fa,
  },
  in: {
    translation: indian,
  },
  pt: {
    translation: pt,
  },
  sa: {
    translation: sa,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: false,
  lng: 'en',
  resources,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
