import { configureStore, ThunkAction, Action, Middleware, isRejected } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import authReducer, { Auth, userLogOut } from '../features/auth/authSlice';
import { apiSlice } from '../features/api/apiSlice';
import uiReducer, { UiSliceType, resetUi } from '../features/ui/uiSlice';
import { notification } from 'antd';
import { sumConnectorService } from '../features/api/sumConnectorService';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  if (
    isRejected(action) &&
    (action.type === 'api/executeMutation/rejected' || action.type === 'api/executeQuery/rejected') &&
    action.payload.status === undefined
  ) {
    notification.error({ message: 'Service unavailable please try again later.' });
  }

  return next(action);
};

const authPersistConfig = {
  key: 'Lumberjack-Dashboard-Auth',
  storage,
};

const uiPersistConfig = {
  key: 'Lumberjack-Dashboard-Ui',
  storage,
};

export const store = configureStore({
  reducer: {
    auth: persistReducer<Auth, any>(authPersistConfig, authReducer),
    ui: persistReducer<UiSliceType, any>(uiPersistConfig, uiReducer),
    [apiSlice.reducerPath]: apiSlice.reducer,
    [sumConnectorService.reducerPath]: sumConnectorService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(apiSlice.middleware)
      .concat(sumConnectorService.middleware)
      .concat(rtkQueryErrorLogger),
  devTools: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
});

export const persistor = persistStore(store);

export const resetStore = () => {
  return new Promise<void>((resolve, reject) => {
    try {
      persistor.pause();

      persistor.flush().then(async () => {
        return await persistor.purge();
      });

      if (navigator.cookieEnabled && typeof window !== 'undefined' && window.localStorage) {
        localStorage.removeItem('_lum_tour');
        localStorage.removeItem('_lum_offer');
        localStorage.removeItem('_lum_credential');
        localStorage.removeItem('_lum_pass_level');
      }

      store.dispatch(apiSlice.util.resetApiState());
      store.dispatch(sumConnectorService.util.resetApiState());

      store.dispatch(userLogOut());
      store.dispatch(resetUi());

      return resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
