import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


const Timer = ({ setShowTimer, ttl }: { setShowTimer: (bool: boolean) => void, ttl: number }) => {
    const [remain, setRemain] = useState(ttl);
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            setRemain(remain - 1)

            if (remain <= 0)
                setShowTimer(false)
        }, 1000)

        return () => clearInterval(interval);

    }, [remain, setShowTimer, ttl])

    return (
        <span>{remain} {t("Global.seconds")}</span>
    )
}

export default Timer