import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  isAuthenticated,
  isActiveAccount,
  isRequiredPasswordUpdate,
  isUserNew,
  isPaymentVerifiedAccount,
  selectCustomerIdentification,
  selectCustomerEmail,
} from '../features/auth/authSlice';

export const useAuth = () => {
  const user_identification = useSelector(selectCustomerIdentification);
  const user_email = useSelector(selectCustomerEmail);
  const is_authenticated = useSelector(isAuthenticated);
  const is_account_active = useSelector(isActiveAccount);
  const is_required_password_update = useSelector(isRequiredPasswordUpdate);
  const is_new_user = useSelector(isUserNew);
  const is_verified_payment_account = useSelector(isPaymentVerifiedAccount);

  return useMemo(
    () => ({
      user_identification,
      user_email,
      is_authenticated,
      is_account_active,
      is_required_password_update,
      is_new_user,
      is_verified_payment_account,
    }),
    [
      is_account_active,
      is_authenticated,
      is_new_user,
      is_required_password_update,
      is_verified_payment_account,
      user_email,
      user_identification,
    ]
  );
};
