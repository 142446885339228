import { Typography } from 'antd'
import { useTranslation } from 'react-i18next';


const PageLoading = () => {
  const { t } = useTranslation();

  return (
    <div className='pageLoading'>
      <img src="/ax.svg" alt="Lumberjack" width={100} />
      <img src="/wood.svg" alt="Lumberjack" width={130} />
      <Typography.Title level={4}>
        {t("Global.Loading...")}
      </Typography.Title>
    </div>
  )
}

export default PageLoading