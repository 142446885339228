import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useLazyProfileQuery, useLazyVerifyPaymentQuery } from '../../features/auth/authService';
import { useEffect } from 'react';
import PageLoading from './PageLoading';
import jwtDecode from 'jwt-decode';
import { useAppSelector } from '../../app/hooks';
import { message } from 'antd';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user_identification, is_authenticated, is_account_active, is_verified_payment_account } = useAuth();
  const token = useAppSelector((state) => state.auth.access_token);

  const [getProfile, getProfileResults] = useLazyProfileQuery();
  const [verifyPayment, verifyPaymentResults] = useLazyVerifyPaymentQuery();

  useEffect(() => {
    if (is_authenticated) {
      if (token) {
        const decodedPayload = jwtDecode<{ [key: string]: any }>(token as string);

        // Check for token expiry
        if (decodedPayload && decodedPayload.exp <= Math.floor(Date.now() / 1000)) {
          return navigate('logout', { replace: true });
        }
      }

      if (is_account_active)
        verifyPayment(user_identification)
          .unwrap()
          .catch((err) => {
            message.error(err.data.message || err.message || 'Failed to authorize payment');
          });

      if (is_verified_payment_account)
        getProfile(user_identification)
          .unwrap()
          .catch((err) => {
            message.error(err.data.message || err.message || 'Failed to fetch profile information');
          });
    }
  }, [
    getProfile,
    is_account_active,
    is_authenticated,
    is_verified_payment_account,
    navigate,
    token,
    user_identification,
    verifyPayment,
  ]);

  let content = <PageLoading />;

  if (!is_authenticated) {
    content = <Navigate to="/logout" state={{ from: location }} replace />;
  }

  if (is_authenticated && !is_account_active) {
    content = <Navigate to="/verify-account" replace />;
  }

  if (is_authenticated && verifyPaymentResults.isSuccess && !is_verified_payment_account) {
    content = <Navigate to="/account-subscription" replace />;
  }

  if (is_authenticated && (verifyPaymentResults.isError || getProfileResults.isError))
    content = (
      <Navigate
        to="/account-failure"
        state={{
          isVerifyError: verifyPaymentResults.isError,
          isGetProfileError: getProfileResults.isError,
        }}
      />
    );

  if (is_authenticated && is_verified_payment_account && is_account_active) {
    content = children;
  }

  return content;
};

export default PrivateRoute;
