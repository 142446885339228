import React, { useEffect } from 'react';
import { resetStore } from './app/store';

const VersionControl = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    if (navigator.cookieEnabled && typeof window !== 'undefined' && window.localStorage) {
      const envVersion = process.env.REACT_APP_VERSION;
      const lastVersion = localStorage.getItem('_lum_version');

      if (envVersion && lastVersion) {
        const versionString = envVersion;
        const versionRegex = /(\d+\.\d+\.\d+\.\d+)/;
        const version = versionString.match(versionRegex)?.[0];

        const versionStringLast = lastVersion;
        const versionRegexLast = /(\d+\.\d+\.\d+\.\d+)/;
        const versionLast = versionStringLast.match(versionRegexLast)?.[0];

        let envVersionSemantics;
        let lastVersionSemantics;

        if (version) {
          const parts = version.split('.');
          envVersionSemantics = parts;
        }

        if (versionLast) {
          const parts = versionLast.split('.');
          lastVersionSemantics = parts;
        }

        if (envVersionSemantics && lastVersionSemantics) {
          // Compare major, minor versions
          let shouldLogout: boolean = false;

          for (let i = 0; i < 4; i++) {
            if (envVersionSemantics[i] !== lastVersionSemantics[i]) {
              localStorage.setItem('_lum_version', envVersion);

              shouldLogout = true;

              break;
            }
          }

          if (shouldLogout) {
            resetStore().then(() => {
              window.location.href = '/logout';
            });
          }

          return;
        }
      } else localStorage.setItem('_lum_version', envVersion as string);
    }
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default VersionControl;
