import { useTranslation } from 'react-i18next';
import { Typography, Row, Col } from 'antd';


const UiError = () => {
  const { t } = useTranslation();

  return (
    <Row align='middle' justify='center'>
      <Col lg={16} md={16} sm={20} xs={24}>
        <div style={{ textAlign: 'center' }}>
          <img src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240651_500.png" alt="Lumberjack" width={500} />
          <Typography.Title style={{ fontSize: 70 }} type='danger'>{t('ErrorPages.Error')}!</Typography.Title>
          <Typography.Paragraph style={{ fontSize: 30 }}>{t('ErrorPages.Uh oh, there seems to be a problem.')}</Typography.Paragraph>
          <a href="/">{t('ErrorPages.Back to dashboard')}</a>
        </div>
      </Col>
    </Row>
  );
};

export default UiError;
