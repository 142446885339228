import React, { useEffect } from 'react';
import { Button, Space, Spin, Typography } from 'antd';
import AuthLayout from '../Auth/Layout';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useLazyProfileQuery, useLazyVerifyPaymentQuery } from '../../features/auth/authService';

type Props = {
  isVerifyError?: boolean;
  isGetProfileError?: boolean;
};

const AccountFailure = () => {
  const { user_identification } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [getProfile, getProfileResults] = useLazyProfileQuery();
  const [verifyPayment, verifyPaymentResults] = useLazyVerifyPaymentQuery();

  const refetchBoth = async () => {
    try {
      await verifyPayment(user_identification);

      await getProfile(user_identification);

      navigate('/control-panel', { replace: true });
    } catch (error) {}
  };

  const refetchVerify = () => {
    verifyPayment(user_identification)
      .unwrap()
      .then(() => {
        navigate('/control-panel', { replace: true });
      });
  };

  const refetchProfile = () => {
    getProfile(user_identification)
      .unwrap()
      .then(() => {
        navigate('/control-panel', { replace: true });
      });
  };

  const renderRefetchButtons = (state: Props) => {
    if (state) {
      if (state.isVerifyError && state.isGetProfileError)
        return (
          <Button onClick={refetchBoth}>
            {getProfileResults.isLoading || verifyPaymentResults.isLoading ? <Spin /> : t('AccountFailurePage.btn')}
          </Button>
        );
      else if (state.isVerifyError)
        return (
          <Button onClick={refetchVerify}>
            {verifyPaymentResults.isLoading ? <Spin /> : t('AccountFailurePage.btn')}
          </Button>
        );
      else if (state.isGetProfileError)
        return (
          <Button onClick={refetchProfile}>
            {getProfileResults.isLoading ? <Spin /> : t('AccountFailurePage.btn')}
          </Button>
        );
      else return null;
    } else return null;
  };

  useEffect(() => {
    if (!location.state || (!location.state.isVerifyError && !location.state.isGetProfileError))
      navigate('/', { replace: true });
  }, [location.state, navigate]);

  return (
    <AuthLayout>
      <Space direction="vertical">
        <Typography.Text>{t('AccountFailurePage.text')}</Typography.Text>
        {renderRefetchButtons(location.state)}
      </Space>
    </AuthLayout>
  );
};

export default AccountFailure;
