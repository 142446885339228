import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from './app/hooks';
import { changeLanguage, layoutDirection } from './features/ui/uiSlice';
import { store } from './app/store';

const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (
      urlParams.get('language') &&
      i18n.options.resources &&
      Object.keys(i18n.options.resources).includes(urlParams.get('language') as string)
    ) {
      const lng = urlParams.get('language') as string;

      i18n.changeLanguage(lng);

      dispatch(changeLanguage(lng));

      dispatch(layoutDirection(lng));
    } else {
      i18n.changeLanguage(store.getState().ui.language);
    }
  }, [dispatch, i18n]);

  return <>{children}</>;
};

export default LanguageProvider;
