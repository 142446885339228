import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { apiSlice } from '../../features/api/apiSlice';
import { userLogOut } from '../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userLogOut());
    dispatch(apiSlice.util.resetApiState());
  }, [dispatch, i18n]);

  return <Navigate to="/login" replace />;
};

export default Logout;
