import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig, AxiosError } from 'axios';
import httpService from '../../features/httpService';
import { store } from '../../app/store';

export interface ResponseData {
  status: string;
  message: string;
  element: any;
}

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    ResponseData,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await httpService.axios({ url: baseUrl + url, method, data, params, headers });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL as string}/`,
  }),
  tagTypes: [
    'TotalShortenedLinks',
    'TotalShortenedLinksVisits',
    'ShortLinks',
    'CompareStats',
    'Profile',
    'VerifyPayment',
    'PlanStatus',
    'PlanSubscriptionHistory',
    'PlanCards',
    'PlanDetailedInfo',
  ],
  endpoints(builder) {
    return {};
  },
});

export const getToken = (): string | null => {
  let token = '';
  const stateToken = store.getState().auth.access_token;

  if (stateToken && stateToken !== '') token = stateToken;
  else if (navigator.cookieEnabled && typeof localStorage !== 'undefined') {
    let parsedCredential = JSON.parse(localStorage.getItem('_lum_credential') as string);
    let localCredential = JSON.parse(localStorage.getItem('_lum') as string);

    token = parsedCredential?.user_access_token || localCredential?.user_access_token;
  } else token = '';

  return token;
};
