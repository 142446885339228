import { getToken } from '../api/apiSlice';
import httpService from '../httpService';

const errorReport = async (error: any) => {
  const res = await httpService.post(`${process.env.REACT_APP_BASE_URL}/reports/ui-bug`, { error });
  return res;
};

const checkUrl = async (customer_identification: string, url: string) => {
  const res = await httpService.post(
    `${process.env.REACT_APP_BASE_URL}/${customer_identification}/test`,
    {
      url,
    },
    {
      headers: {
        'x-access-token': getToken() as string,
      },
    }
  );

  return res;
};

const uiService = {
  errorReport,
  checkUrl,
};

export default uiService;
