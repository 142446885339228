import React, { useEffect } from 'react';
import AuthLayout from '../Auth/Layout';
import PlanSelector from '../../components/common/PlanSelector';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const VerifyPayment = () => {
  const navigate = useNavigate();
  const { is_authenticated, user_identification, user_email, is_verified_payment_account } = useAuth();

  useEffect(() => {
    if (!is_authenticated || is_verified_payment_account) navigate('/');
  }, [is_authenticated, is_verified_payment_account, navigate]);

  return (
    <AuthLayout>
      <PlanSelector
        closable={false}
        isModalVisible={true}
        user_identification={user_identification}
        user_email={user_email}
        setShowPlansModal={() => null}
        asModal={false}
      />
    </AuthLayout>
  );
};

export default VerifyPayment;
